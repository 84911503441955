import { Box, Button, Container, Tooltip, Typography } from '@mui/material';
import React from 'react';
// import { useTitle } from '../../../hooks/useTitle';
import { Link } from 'react-router-dom';
import Project from '../Project/Project';
import { useState } from 'react';
import { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//fake database in public section

const ProjectsSummary = () => {
    // useTitle('Sharif Hossain | Projects');

    // Configuration options for the carousel
    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1079,
                settings: {
                slidesToShow: 1,
                },
            },
        ],
    };

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('fakeDBProjects.json')
            .then(res => res.json())
            .then(data => setProjects(data))
    }, [])

    return (
        <Box id='projects' sx={{ backgroundColor: 'lightgray', p:1 }}>
            <Container sx={{ flexGrow: 1 }}>
                <Typography sx={{ pt: 3, color: '#2b6777', fontWeight: 600 }} gutterBottom variant="h4" component="div">
                    Featured Projects
                </Typography>

                {/* <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                    {
                        projects.slice(0, 2).map(project => <Project
                            key={project.ID}
                            project={project}
                        ></Project>)
                    }
                </Grid> */}

                <Slider {...carouselSettings}>
                    {projects.slice(0, 10).map((project) => (
                        <Project key={project.ID} project={project} />
                    ))}
                </Slider>
            </Container>
            <Tooltip title="Click for details">
                <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ mb: 2, mt:5, textDecoration: 'none' }}>
                    <Link to={'/projects'} style={{textDecoration: 'none', color:'white'}}>See More Projects</Link>
                </Button>
                
            </Tooltip>
            
        </Box>
    );
};

export default ProjectsSummary;