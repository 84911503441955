import React from 'react';
import { send } from 'emailjs-com';
import { useState } from 'react';
import { Button, Container, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
// import { useTitle } from '../../../hooks/useTitle';


const Contact = () => {
    // useTitle('Sharif Hossain | Contact');

    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: 'Mr. Sharif Hossain',
        message: '',
        reply_to: '',
    });

    const handleContactSubmit = (e) => {
        e.preventDefault();
        send(
        'service_0pevuoc',
        'template_9089qij',
        toSend,
        'l0RRa81Jox7aafKFN'
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Your message has been sent successfully!');
            window.location.reload(true);
        })
        .catch((err) => {
            console.log('FAILED...', err);
            alert('ERROR' , {err});
        });
    };
    
      const handleOnChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    return (
        <Box id='contact' style={{backgroundColor: '#52ab98'}}>
            <Container sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid xs={4}>
                        <Typography sx={{ pt: 5, pl:3, fontWeight: 500 }} style={{color: '#f2f2f2', textAlign: 'left'}} variant="h4" component="div">
                            Let’s talk business
                        </Typography>
                        <Typography sx={{ py: 5, pl:3, fontWeight: 500 }} style={{color: '#f2f2f2', textAlign: 'left'}} variant="h6" component="div">
                            Now that you know a lot about me, let me know if you are interested to work with me.
                        </Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Container>
                            <form onSubmit={handleContactSubmit}>
                                <TextField
                                    style={{backgroundColor: '#f2f2f2'}}
                                    required
                                    sx={{width: "80%", m:1 , mt:5}}
                                    id="outlined-size-small"
                                    placeholder='Email address *'
                                    name='reply_to' 
                                    type='email'
                                    value={toSend.reply_to}
                                    onChange={handleOnChange}
                                    size="small"
                                    />
                                <TextField
                                    style={{backgroundColor: '#f2f2f2'}}
                                    required
                                    sx={{width: "80%", m:1 }}
                                    id="outlined-size-small"
                                    placeholder='Name *'
                                    name='from_name' 
                                    type='text'
                                    value={toSend.from_name}
                                    onChange={handleOnChange}
                                    size="small"
                                    />
                                <TextField
                                    style={{backgroundColor: '#f2f2f2'}}
                                    required
                                    sx={{width: "80%", m:1 }}
                                    id="filled-multiline-static"
                                    multiline
                                    rows={4}
                                    placeholder='Message *'
                                    name='message' 
                                    type='text'
                                    value={toSend.message}
                                    onChange={handleOnChange}
                                    variant="filled"
                                    />
                                    <br /><br />
                                <Button type='submit' sx={{mt:3}} variant="contained" style={{backgroundColor: '#f2f2f2', color: '#2b6777'}}>Let's Get Started</Button>
                                <br /><br /><br />
                            </form>
                        </Container>
                    </Grid>
                </Grid>
            </Container>

            {/* <Container>
                <form onSubmit={handleContactSubmit}>
                    <TextField
                        style={{backgroundColor: '#f2f2f2'}}
                        required
                        sx={{width: "80%", m:1 }}
                        id="outlined-size-small"
                        placeholder='Your Email*'
                        name='reply_to' 
                        type='email'
                        value={toSend.reply_to}
                        onChange={handleOnChange}
                        size="small"
                        />
                    <TextField
                        style={{backgroundColor: '#f2f2f2'}}
                        // required
                        sx={{width: "80%", m:1 }}
                        id="outlined-size-small"
                        placeholder='Your name*'
                        name='from_name' 
                        type='text'
                        value={toSend.from_name}
                        onChange={handleOnChange}
                        size="small"
                        />
                    <TextField
                        style={{backgroundColor: '#f2f2f2'}}
                        required
                        sx={{width: "80%", m:1 }}
                        id="filled-multiline-static"
                        multiline
                        rows={4}
                        placeholder='Your message*'
                        name='message' 
                        type='text'
                        value={toSend.message}
                        onChange={handleOnChange}
                        variant="filled"
                        />
                        <br /><br />
                    <Button type='submit' sx={{mt:3}} variant="contained" style={{backgroundColor: '#f2f2f2', color: '#2b6777'}}>Send message</Button>
                    <br /><br /><br />
                </form>
            </Container> */}
        </Box>
    );
};

export default Contact;