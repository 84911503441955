import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// https://i.ibb.co/h16ZpKk/GMAT-overview-resources-sharif-hossain.png
// https://i.ibb.co/gJntLdc/GRE-overview-resources-sharif-hossain.png
// https://i.ibb.co/gz0KsNY/IELTS-overview-resources-sharif-hossain.png
// https://i.ibb.co/5RQT5Sp/TOEFL-overview-resources-sharif-hossain.png

const Article = (props) => {
    const { articleLink, title, miniDetail, thumbnail } = props.myArticle;
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div style={{ mx: 5 }}>
            <Link to={articleLink} style={{textDecoration: 'none'}}>
                <Container 
                style={{ 
                    backgroundColor: '#ffffff', 
                    // display: 'flex', 
                    // flexWrap: "wrap", 
                    // flexDirection: 'row', 
                    // justifyContent: 'space-around', 
                    alignItems: 'center',
                    border: '7px solid #2b6777',
                    borderRadius: '15px'
                    }}
                sx={{
                    // maxWidth: 345,
                    transition: 'transform 0.5s', // Adding transition for the zoom effect
                    transform: isHovered ? 'scale(1.02)' : 'scale(1)', // Apply the zoom effect on hover
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                    >

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={0}>
                            <Grid xs={8} md={8}>
                                <Typography style={{textAlign: 'left'}} component="div" variant="h6">
                                    <a style={{textDecoration: 'none', color: '#2b6777'}} href={articleLink}>{title}</a>
                                </Typography>
                                <Typography style={{textAlign: 'left'}} variant="subtitle2" color="text.secondary" component="div">
                                    {miniDetail}
                                </Typography>
                            </Grid>
                            <Grid xs={4} md={4}>
                                <img src={thumbnail} alt={title} style={{ maxHeight: 50, maxWidth: 'auto', margin: '5px' }} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Link>
        </div>
    );
};

export default Article;