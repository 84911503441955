// import { Card, CardContent, CardMedia } from '@mui/material';
// import { Typography } from '@mui/material';
import { Container } from '@mui/material';
// import { Box } from '@mui/system';
import React, { useState } from 'react';

const Technology = (props) => {
    const {platform, link, logo} = props.myTechnology;
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div style={{ mx: 5 }}>
            {/* <Container>
                <Card sx={{ display: 'flex', m:2 }} style={{backgroundColor: ''}}>
                    <CardMedia
                        component="img"
                        sx={{ width: '25%', height: '25%', ml:2 }}
                        image={logo}
                        alt={logo}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography style={{textAlign: 'right'}} component="div" variant="h5">
                                <a style={{textDecoration: 'none', color: '#2b6777'}} href={link}>{platform}</a>
                            </Typography>
                            <Typography style={{textAlign: 'right'}} variant="subtitle1" color="text.secondary" component="div">
                                {title}
                            </Typography>
                        </CardContent>
                    </Box>
                </Card>
            </Container> */}
            
            <a href={link} target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                <Container 
                style={{ 
                    backgroundColor: '#ffffff', 
                    display: 'flex', 
                    flexWrap: "wrap", 
                    flexDirection: 'row', 
                    justifyContent: 'space-around', 
                    alignItems: 'center',
                    border: '7px solid lightgray',
                    borderRadius: '15px',
                    height: '85px', // Set a fixed height for the container
                    overflow: 'hidden'
                    }}
                sx={{
                    // maxWidth: 345,
                    transition: 'transform 0.5s', // Adding transition for the zoom effect
                    transform: isHovered ? 'scale(1.1)' : 'scale(1)', // Apply the zoom effect on hover
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                    >
                    <img 
                        src={logo} 
                        alt={platform} 
                        style={{ width: '40%', margin: '10px 0px' }}
                     />
                    {/* <div className="">
                        <Typography style={{textAlign: 'right'}} component="div" variant="body1">
                            <a style={{textDecoration: 'none', color: '#2b6777'}} href={link}>{platform}</a>
                        </Typography>
                        <Typography style={{textAlign: 'right'}} variant="subtitle2" color="text.secondary" component="div">
                            {platform}
                        </Typography>
                    </div> */}
                </Container>
            </a>
        </div>
    );
};

export default Technology;