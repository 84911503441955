import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import bannerImg from '../../../images/sharif-hossain-image.jpeg';
import sharifHossainCV from '../../../assets/Md Sharif Hossain-CV-May-2024.pdf';



const Banner = () => {
    // const texts = [ "Md Sharif Hossain." ];

    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentText, setCurrentText] = useState("");

    useEffect(() => {
        const texts = [ "Md Sharif Hossain." ];

        const interval = setInterval(() => {
            if (currentText.length === texts[currentTextIndex].length) {
                clearInterval(interval);
                setTimeout(() => {
                    setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
                    setCurrentText("");
                }, 1000);
            } else {
                setCurrentText((prevText) => prevText + texts[currentTextIndex][prevText.length]);
            }
        }, 75);

        return () => clearInterval(interval);
    }, [currentText, currentTextIndex]);

    return (
        <Box sx={{ flexGrow: 1, pt:3, pb:5, backgroundColor: '#253746' }}>
            <Container>
                <Grid container spacing={0} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Grid xs={5} md={4}>
                        <img style={{ width: '70%', borderRadius: '1%' }} src={bannerImg} alt="" />
                    </Grid>
                    <Grid xs={10} md={8} sx={{ml: 0}}>
                        <box sx={{  }}>
                            <Typography sx={{ pt: 3, px:3, fontWeight: 500, fontSize: 35 }} style={{color: '#f2f2f2', textAlign: 'left'}} variant="h1" component="div" className="minHeightBox">
                                I'm {currentText}
                            </Typography>
                        </box>
                        <Typography sx={{ pt: 0, px:3, fontWeight: 500, fontSize: 30 }} style={{color: '#f2f2f2', textAlign: 'left'}} variant="h4" component="div">
                            A professional Web Developer and IT Solutions Expert.
                        </Typography>
                        <Typography sx={{ pt: 2, px:3, fontWeight: 500 }} style={{color: '#f2f2f2', textAlign: 'left'}} variant="text" component="div">
                            I’m probably the most passionate developer cum analyst you will ever get to work with. 
                            
                            If you have a great project that needs some amazing skills, I’m your guy. 
                        </Typography>
                        <Button sx={{ mt:3, color: '#f2f2f2', }} variant="outlined" startIcon={<DownloadIcon />}>
                            <a href={sharifHossainCV} target="blank" style={{ textDecoration: 'none', color: '#f2f2f2',}}>Download CV</a>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Banner;