import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import OnlineEngagement from '../OnlineEngagement/OnlineEngagement';
import { Box } from '@mui/system';

const onlinePlatforms = [
    {
        platform: 'GitHub',
        link: 'https://github.com/Sharif-00112',
        logo: 'https://i.ibb.co/SDg4kCW/github-com-Sharif-00112.png',
        title: 'Sharif-00112'
    },
    {
        platform: 'LinkedIn',
        link: 'https://www.linkedin.com/in/sharif00112/',
        logo: 'https://i.ibb.co/SVB8rV8/linkedin-com-in-sharif00112.png',
        title: 'sharif00112'
    },
    // {
    //     platform: 'Fiverr',
    //     link: 'https://www.fiverr.com/shossain00112',
    //     logo: 'https://i.ibb.co/M2MGPmG/fiverr-com-shossain00112.png',
    //     title: 'shossain00112'
    // },
    {
        platform: 'Email',
        link: 'mailto:sh.hossain.24@gmail.com',
        logo: 'https://i.ibb.co/XL6Dypt/sharif-hossain-email.png',
        title: 'sh.hossain.24'
    },
    {
        platform: 'FaceBook',
        link: 'https://www.facebook.com/sharif.00112/',
        logo: 'https://i.ibb.co/Zdzb8N1/facebook-com-sharif-00112-svg.png',
        title: 'sharif.00112'
    },
    // {
    //     platform: 'Twitter',
    //     link: 'https://twitter.com/Sharif_00112',
    //     logo: 'https://i.ibb.co/qg7SZnK/twitter-com-Sharif-00112.jpg',
    //     title: 'Sharif_00112'
    // },
    // {
    //     platform: 'Website',
    //     link: 'https://sharif-hossain.com',
    //     logo: 'https://i.ibb.co/m99Bzts/web.png',
    //     title: 'sharif-hossain.com'
    // },
    // {
    //     platform: 'WhatsApp',
    //     link: "whatsapp://send?text=Hello Sharif!&phone=+8801773241632",
    //     logo: 'https://i.ibb.co/XkXHLqs/wpp.png',
    //     title: '+8801773241632'
    // }
];

const OnlineEngagements = () => {
    return (
        <div style={{backgroundColor: '#2b6777'}}>
            <Container>
                <Box sx={{ flexGrow: 1, py:2 }}>
                    <Typography sx={{ p: 2, color: '#f2f2f2', fontWeight: 700 }} variant="h4" component="div">
                        Appearances
                    </Typography>

                    <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        {
                            onlinePlatforms.map(onlinePlatform => (
                                <Grid xs={6} sm={6} md={3} key={onlinePlatform.link}>
                                    <OnlineEngagement
                                        key = {onlinePlatform.link}
                                        onlinePlatform = {onlinePlatform}
                                    ></OnlineEngagement>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
        </div>
    );
};

export default OnlineEngagements;