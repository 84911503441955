import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import React from 'react';
// import { useTitle } from '../../../hooks/useTitle';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const AboutSummary = () => {
    // useTitle('About|Sharif Hossain');

    return ( 
        <div id='about'>
            {/* <hr /> */}
            <Typography sx={{ m: 3, color: '#2b6777', fontWeight: 600 }} variant="h4" component="div">
                About me
            </Typography>
            <Container sx={{width: 'auto'}}>
                <Box sx={{ flexGrow: 1, mb: 0 }}>
                    <Grid container spacing={2}>
                        <Grid xs={8} md={8}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                    <Tooltip
                                            title={
                                                <React.Fragment>
                                                <ul style={{ margin: 0, padding: '0 0 0 20px' }}> {/* Adjust padding and margin as needed */}
                                                    <li>Developing, debugging and maintaining web and ecommerce platforms.</li>
                                                    <li>Implementing, training and maintaining industrial ERP, sales force tracking software, VAT and HRM software, and secondary sales software.</li>
                                                    <li>Data Analysis, problem solving and generating BI reports.</li>
                                                </ul>
                                                </React.Fragment>
                                            }
                                            >
                                            <Typography variant="h5" component="div">
                                                Executive IT Engineer
                                            </Typography>
                                        </Tooltip>
                                        <Typography sx={{ mb: 1 }} color="text.secondary">
                                            Bengal Group of Industries
                                        </Typography>
                                        {/* <Typography variant="body2">
                                            June 2022 to Present
                                        </Typography> */}
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid xs={4} md={4}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Profession
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid xs={4} md={4}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Academic
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                        <Grid xs={8} md={8}>
                            <Box>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            BS in Computer Science and Engineering
                                        </Typography>
                                        <Typography sx={{ mb: 1 }} color="text.secondary">
                                            North South University (NSU)
                                        </Typography>
                                        {/* <Typography variant="body2">
                                            Graduated in 2021
                                        </Typography> */}
                                    </CardContent>
                                </Card> 
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Tooltip title="Click for details">
                    <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ mt: 2, mb: 3, textDecoration: 'none' }}>
                        <Link to={'/about'} style={{ textDecoration: 'none', color: 'white' }}>More About Me</Link>
                    </Button>
                </Tooltip>
                
            </Container>
        </div>
    );
};

export default AboutSummary;