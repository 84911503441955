import { Box, Button, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTitle } from '../../../hooks/useTitle';
import greImg from '../../../images/articles/GRE_overview_resources_sharif-hossain.png';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'; 
import ShareThisLinkDark from '../../Shared/ShareThisLink/ShareThisLinkDark';
import { Helmet } from 'react-helmet';

const GRE = () => {
    useTitle('GRE Overview | Sharif Hossain');
    return (
        <div>
            {/* <Typography>GRE</Typography> */}
            <Helmet>
                <title>GRE Overview | Sharif Hossain</title>
                <meta name="description" content="GRE overview and free resources" />
                <meta name="keywords" content="sharif, hossain, nsu, north south university, gre, gre overview, gre guide, self gre, gre resources, free gre resources" />
            </Helmet> 

            <Typography sx={{ m: 3, mb:5, color: '#2b6777', fontWeight: 600 }} variant="h4" component="div">
                Graduate Record Examinations (GRE) Overview
            </Typography>
            <Typography sx={{ mx: 4, my:3, color: '', fontWeight: 500, textAlign: 'right' }} variant="body1" component="div">
                <span><b>Author:</b></span> Md Sharif Hossain 
                <br/>
                <span><b>Published at:</b></span> December 2023
                <br/>
                <span><b>Last modified:</b></span> May 2024
            </Typography> 
            <img style={{ width: '50%', borderRadius: '0%' }} src={greImg} alt="gre overview and free resources" />
            <br/> <br/>
            <Typography sx={{ mx: 4, mb:3, color: '', fontWeight: 600 }} variant="h6" component="div">
                 <i>"If you know the enemy and know yourself, you need not fear the result of a hundred battles. If you know yourself but not the enemy, for every victory gained, you will also suffer a defeat. If you know neither the enemy nor yourself, you will succumb in every battle." 
                 <br/>—Sun Tzu (The Art of War)</i>
            </Typography>

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
                A brief on GRE:  
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                You can prepare for the GRE test from home. First, you need to know about the test. Considering the GRE test as your enemy, you need to be clear about the question types, the requirements, the question complexity level, and finally, your own command over the designated fields. To check your level, simply register on the ETS website and try a free mock test. You can follow their website (GRE section) in-depth to gain a better understanding of the test.  
            </Typography>
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                The Graduate Record Examinations (GRE) is a standardized test that is part of the admissions process for many graduate schools in the United States, Canada, and a few other countries. The GRE is owned and administered by the Educational Testing Service (ETS). 
                <br/> <br/>
                Let's go through some common information- 
            </Typography> 

            <Box sx={{ mx: 5 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30%' }}><b>Official site</b></TableCell>
                                <TableCell style={{ width: '70%' }}><a href='https://www.ets.org/gre.html' target='blank'>www.ets.org/gre.html </a></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Test Taker</b></TableCell>
                                <TableCell>Educational Testing Service (ETS) </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test types</b></TableCell>
                                <TableCell>
                                    <li>GRE General Test </li>
                                    <li>GRE Subject Test </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Testing measures</b></TableCell>
                                <TableCell>
                                    <li>Verbal Reasoning </li>
                                    <li>Quantitative Reasoning </li>
                                    <li>Analytical Writing </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Score Range</b></TableCell>
                                <TableCell>
                                    <li>Quant: 130-170, one-point increments </li>
                                    <li>Verbal: 130-170, one-point increments </li>
                                    <li>Analytical: 0-6, half-point increments </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test fee</b></TableCell>
                                <TableCell>
                                    <li>GRE General Test: $ 220 </li>
                                    <li>GRE Subject Test: $ 150  </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test system</b></TableCell>
                                <TableCell>
                                    <li>GRE at the test center </li>
                                    <li>GRE at home  </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test media</b></TableCell>
                                <TableCell>Computer-based </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Identification method</b></TableCell>
                                <TableCell>Passport </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Result Pending Time </b></TableCell>
                                <TableCell>Approximately 8–15 days after the test date </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Result validation </b></TableCell>
                                <TableCell>5 years following the individual's test date </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <br/><br/>
            
            {/* title-text pair */}
            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                GRE General Test Structure:
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                The GRE General Test is a computer-delivered test. Its test-taker-friendly design lets you skip questions within a section, go back and change answers, and have the flexibility to choose which questions within a section you want to answer first.
            </Typography> 

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Test sections and timing (beginning September 22, 2023):
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                The overall test time is about 1 hour and 58 minutes. There are five sections. The Analytical Writing section will always be first. The Verbal Reasoning and Quantitative Reasoning sections may appear in any order after the Analytical Writing section. 
            </Typography> 

            <Box sx={{ mx: 5 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '33%', fontWeight: 700 }}>Measure</TableCell>
                                <TableCell style={{ width: '34%', fontWeight: 700 }}>Number of Questions</TableCell>
                                <TableCell style={{ width: '33%', fontWeight: 700 }}>Allowed Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Analytical Writing (One section)</TableCell>
                                <TableCell>One "Analyze an Issue" task </TableCell>
                                <TableCell>30 minutes </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Verbal Reasoning (Two sections)</TableCell>
                                <TableCell>
                                    <li>Section 1: 12 questions</li>
                                    <li>Section 2: 15 questions</li>
                                </TableCell>
                                <TableCell>
                                    <li>Section 1: 18 minutes </li>
                                    <li>Section 2: 23 minutes </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Quantitative Reasoning (Two sections)</TableCell>
                                <TableCell>
                                    <li>Section 1: 12 questions</li>
                                    <li>Section 2: 15 questions  </li>
                                </TableCell>
                                <TableCell>
                                    <li>Section 1: 21 minutes</li>
                                    <li>Section 2: 26 minutes </li>
                                </TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <br/>
            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Section-level adaptation:
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
            The Verbal Reasoning and Quantitative Reasoning sections are section-level adaptive. The first section of each measure (i.e., Verbal and Quantitative) is of average difficulty. The difficulty level of the second section of each of the measures depends on your overall performance in the first section.
            <br/><br/>
            For example, if you do very well on the first Quantitative Reasoning section, the second section of Quantitative Reasoning will be at a higher level of difficulty. The scoring for the Verbal Reasoning and Quantitative Reasoning measures takes into consideration the total number of questions answered correctly across the two sections, as well as the difficulty level of the sections.
            </Typography> 

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Test design features:
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
            The advanced adaptive design of the GRE General Test allows you to move forward and backward throughout an entire section. Specific features include:
                <ul>
                    <li>Preview and review capabilities within a section. </li>
                    <li>"Mark" and "Review" features to tag questions so you can skip them and return later if you have time remaining in the section.  </li>
                    <li>The ability to change/edit answers within a section.  </li>
                    <li>An on-screen calculator for the Quantitative Reasoning section.  </li>
                </ul>
            To experience the test design features, access the free POWERPREP Online practice tests from the official ETS website. 
            </Typography> 

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
                Self Preparation: 
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                You can prepare for the GRE test from home. First, you need to know about the test. Considering the GRE test as your enemy, you need to be clear about the question types, the requirements, the question complexity level, and finally, your own command over the designated fields. To check your level, simply register on the ETS website and try a free mock test. You can follow their website (GRE section) in-depth to gain a better understanding of the test.
            </Typography> 

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
                Important resources:  
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                I personally do not recommend following a lot of resources, but rather, I recommend following a limited number of good resources with greater depth. I found the following resources very helpful in preparing for the GRE test. 
                <ol>
                    <li>
                        For any test, official test guides should be the first priority to be introduced to the test. ETS, the test taker of the GRE test, has 4 main resources. The titles are as follows: 
                        <ul>
                            <li><b>The Official Guide to the GRE® General Test, Fourth Edition.</b></li>
                            <li><b>Official GRE® Verbal Reasoning Practice Questions Volume 1, Second Edition. </b></li>
                            <li><b>Official GRE® Quantitative Reasoning Practice Questions Volume 1, Second Edition.</b></li>
                            <li><b>GRE Big Book (Verbal only). </b></li>
                        </ul>
                    </li>
                    <li>
                        For basic understanding, you might take help from this book: 
                        <ul>
                            <li><b>Manhattan Prep's GRE Strategy Guides, Volume 1-6 (Quant).</b></li>
                            <li><b>Manhattan Prep's GRE Strategy Guides, Volume 7-8 (Verbal).</b></li>
                        </ul>
                    </li>
                    <li>
                        For additional practice, this could be of good help: 
                        <ul>
                            <li><b>Manhattan Prep's 5 lb. </b></li>
                        </ul>
                    </li>
                    <li>
                        There are also a number of online GRE resources that can help you. I found Gregmat (Prepswift) to be one of the best online resources. It is easy to understand, cheap, and less time-consuming. You might try Magoosh also. 
                        <ul>
                            <li><b>Gregmat: <a href='https://www.gregmat.com' target='blank'>www.gregmat.com</a></b></li>
                            <li><b>Prepswift: <a href='https://www.prepswift.com' target='blank'>www.prepswift.com</a></b></li>
                            <li><b>Magoosh: <a href='https://gre.magoosh.com' target='blank'>gre.magoosh.com</a></b></li>
                            <li><b>Youtube: Talha Omer - <a href=' https://www.youtube.com/@talhaomer167' target='blank'> www.youtube.com/@talhaomer167</a></b></li>
                        </ul>
                    </li>
                    <li>
                        Finally, consider solving the <span><b>KMF Practice Problems.</b></span>
                    </li>

                </ol>
            </Typography> 
            
            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
            Source of the necessary materials: 
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                All of these resources are available in bookshops and online. Some of them might not be free. However, here I am sharing some online and free versions of important resources. I DO NOT recommend using pirated resources, but as a student of a poor economic country, we might reconsider it. Please read the Disclaimer first (uploaded in the home directory of the shared folder).
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Google Drive Link- <a style={{ textDecoration: '', color: 'blue'}} href='https://drive.google.com/drive/folders/19DC0_8cP9v1yaspGqUh64Op7ZlpZYix0?usp=sharing' target='blank'>Click Here</a>
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Author's Email- <a style={{ textDecoration: '', color: '#253746'}} href='mailto:sh.hossain.24@gmail.com' target='blank'>sh.hossain.24@gmail.com</a> | <a style={{ textDecoration: '', color: '#253746'}} href='https://www.facebook.com/sharif.00112' target='blank'>Facebook</a>
            </Typography> 


            <Tooltip title="Click for the full version">
                <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ m:2, textDecoration: 'none' }}>
                    {/* <Link to={'/about'} style={{ textDecoration: 'none', color: 'white' }}>More About Me</Link> */}
                    <a href='https://docs.google.com/document/d/1GZvpZ4pllChHf_C2JtkRGpgmZ2F8MzTfXjEs6sd6TD0/edit?usp=sharing' target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Read in Google Doc</a>
                </Button>
            </Tooltip>
            <Tooltip title="Click to view resources">
                <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ m: 2, textDecoration: 'none' }}>
                    {/* <Link to={'/about'} style={{ textDecoration: 'none', color: 'white' }}>More About Me</Link> */}
                    <a href='https://drive.google.com/drive/folders/19DC0_8cP9v1yaspGqUh64Op7ZlpZYix0?usp=sharing' target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Resources</a>
                </Button>
            </Tooltip>
            
            <Typography sx={{ mx: 10, mb:5, mt:3, color: '', fontWeight: 700 }} variant="body1" component="div">
                <i>[Note: Please send an access request if any link is restricted]</i> 
            </Typography>

            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 700, textAlign: 'center' }} variant="body1" component="div">
                Thanks, and the best wishes!
            </Typography> 

            {/* share the article button*/}
            <ShareThisLinkDark buttonName="Share This Article" />
            <br/><br/>

            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 300, textAlign: 'left' }} variant="body2" component="div">
                Information source: <a style={{ textDecoration: '', color: 'black'}} href='https://www.ets.org/gre.html' target='blank'>ETS official website </a>
            </Typography> 
        </div>
    )
}

export default GRE