import { Button } from '@mui/material';
import React from 'react';
import ShareIcon from '@mui/icons-material/Share';


class ShareThisLinkDark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageLink: window.location.href
    };
    this.handleShare = this.handleShare.bind(this);
  }

  handleShare() {
    // Copy link to clipboard
    navigator.clipboard.writeText(this.state.currentPageLink)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });

    // Check if the Web Share API is supported by the browser
    if (navigator.share) {
      try {
        // Use the Web Share API to trigger the native share dialog
        navigator.share({
          title: document.title, // The title of the shared link (optional)
          url: this.state.currentPageLink // The URL to be shared
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      console.log('Web Share API not supported.');
    }
  }

  render() {
    return (
      <div>
        {/* <button onClick={this.handleShare}>
          Share This Link
        </button> */}
        <Button onClick={this.handleShare} 
            sx={{ mt:1, mr:2 }} variant="contained" 
            startIcon={<ShareIcon />}
            style={{backgroundColor: '#253746', color: '#f2f2f2'}}>
                {this.props.buttonName}
        </Button>
      </div>
    );
  }
}

export default ShareThisLinkDark;


// invoking example: 
// <ShareThisLinkDark buttonName="Share This Article" />