import { Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { Link } from 'react-router-dom';
import Project from '../Project/Project';
import { useState } from 'react';
import { useEffect } from 'react';

//fake database in public section

const ProjectsMain = () => {
    useTitle('Sharif Hossain | Projects');

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('fakeDBProjects.json')
            .then(res => res.json())
            .then(data => setProjects(data))
    }, [])

    return (
        <Container id='projects'>
            <Container sx={{ flexGrow: 1 }}>
                <Typography sx={{ mt: 3, color: '#2b6777', fontWeight: 600 }} gutterBottom variant="h4" component="div">
                    Featured Projects
                </Typography>

                <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                    {
                        projects.map(project => <Project
                            key={project.ID}
                            project={project}
                        ></Project>)
                    }
                </Grid>
            </Container>
            <Typography sx={{ m: 1, color: '#2b6777', fontWeight: 400 }} variant="h6" component="div">
                Interested to explore more?
            </Typography>
            <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ mb: 2, textDecoration: 'none' }}>
                <Link to={'/contact'} style={{textDecoration: 'none', color:'white'}}>Contact Me</Link>
            </Button>
        </Container>
    );
};

export default ProjectsMain;