// BackToTopButton.js
import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show the button when the user scrolls down 300px
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Fab
    //   color="secondary"
      aria-label="back-to-top"
      style={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000, // Make it appear on top
        display: isVisible ? 'block' : 'none',
        backgroundColor: '#FFFFFF', // Set background color 
      }}
      onClick={scrollToTop}
    >
      <ArrowUpwardIcon />
    </Fab>
  );
};

export default BackToTopButton;
