import { Button, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

const NotAvailable = () => {
    return (
        <div style={{backgroundColor: '#ffffff'}}>
        <Typography sx={{ py:3, fontSize: 25, fontWeight: 600 }} style={{color: '#2b6777 '}} variant="h4" component="div">
            Sorry, The page is temporarily unavailable
        </Typography>
        <Typography sx={{ py:1, fontSize: 18, fontWeight: 500 }} style={{color: 'black'}} variant="h6" component="div">
            The possible reason for the unavailability could be any of the followings: 
        </Typography>
        <Typography sx={{ py:1, fontSize: 14, fontWeight: 400, textAlign: '', alignItems: 'left' }} style={{color: 'black'}} variant="body1" component="div">
            <ol>- The information is private or confidential</ol>
            <ol>- The data is inappropriate for this section</ol>
            <ol>- The page is under construction</ol>
            <ol>- The link has been changed or moved permanently</ol>
        </Typography>
        <br />
        <NavLink 
            style={{ textDecoration:'none' }}
            to='/'>
            <Button variant="contained" sx={{ mb:3 }} style={{backgroundColor: '#2b6777 ', color: '#f2f2f2'}}>Go to Home</Button>
        </NavLink>
    </div>
    );
};

export default NotAvailable;