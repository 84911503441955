import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Project = (props) => {
    const { ID, title, shortDescription, technologiesUsed, liveProjectLink, imgURL } = props.project;
    const [isHovered, setIsHovered] = useState(false);

    const navigate = useNavigate();

    const handleViewDetails = () => {
        navigate(`project?id=${ID}`);
    };

    return (
        <Grid xs={12} sm={12} md={6}>
            <Card 
                sx={{ 
                    display: 'grid', gridTemplateColumns: '200px 1fr', gridTemplateRows: '1fr auto', m:2, 
                    transition: 'transform 0.5s', 
                    transform: isHovered ? 'scale(1.05)' : 'scale(1)', 
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}    
            >
                <CardMedia
                    component="img"
                    src={imgURL}
                    alt={title}
                    sx={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        '@media (max-width: 600px)': {
                        maxWidth: '100%',
                        },
                    }}
                />
                <CardContent sx={{ display: 'grid', gridRow: 'span 2', padding: '16px' }}>
                    <Typography gutterBottom variant="h6" component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
                        {shortDescription}
                    </Typography>
                    <Typography variant="body1" color="black" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }}>
                        Technologies: {technologiesUsed}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <a href={liveProjectLink} target="_blank" rel="noopener noreferrer">Live Preview</a>
                    </Typography>
                    <Button sx={{mt:1, mx:'auto'}} size="small" variant='outlined' onClick={handleViewDetails}>
                        View Details
                    </Button>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Project;
