import React from 'react';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
// import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import FacebookTwoToneIcon from '@mui/icons-material/FacebookTwoTone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import ShareThisLinkLight from '../ShareThisLink/ShareThisLinkLight';

const Footer = () => {

    return (
        // <div style={{ backgroundColor: '#2b6777' }}>
        <div style={{ backgroundColor: '#253746' }}>
            <Container sx={{ py: 2, px:10 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>

                        <Grid item style={{ textAlign: 'left'}} xs={8} md={7}>
                            
                            <Typography sx={{ my:1, fontSize: 18, fontWeight: 600 }} style={{color: '#c8d8e4'}} variant="h6" component="div">
                                Available on
                            </Typography>

                            <a href="https://www.facebook.com/sharif.00112" target="blank" style={{ textDecoration:'none', color:'white' }}>
                                <Button sx={{ mr:2, mb:1 }} variant="contained" style={{backgroundColor: '#f2f2f2', color: '#2b6777'}}>
                                    {<FacebookTwoToneIcon/>}
                                </Button>
                            </a>
                            <a href="https://www.linkedin.com/in/sharif00112/" target="blank" style={{ textDecoration:'none', color:'white' }}>
                                <Button sx={{ mr:2, mb:1 }} variant="contained" style={{backgroundColor: '#f2f2f2', color: '#2b6777'}}>
                                    {<LinkedInIcon/>}
                                </Button>
                            </a>
                            <a href="https://github.com/Sharif-00112" target="blank" style={{ textDecoration:'none', color:'white' }}>
                                <Button sx={{ mr:2, mb:1 }} variant="contained" style={{backgroundColor: '#f2f2f2', color: '#2b6777'}}>
                                    {<GitHubIcon/>}
                                </Button>
                            </a>
                            <a href="mailto:sh.hossain.24@gmail.com" target="blank" style={{ textDecoration:'none', color:'white' }}>
                                <Button sx={{ mr:2, mb:1 }} variant="contained" style={{backgroundColor: '#f2f2f2', color: '#2b6777'}}>
                                    {<EmailIcon/>}
                                </Button>
                            </a>

                            <Typography sx={{ mt:2, fontSize: 16, fontWeight: 400, color:'#f2f2f2' }} variant="" component="div">
                                    Call Now
                            </Typography>
                            <a href="tel:+8801773241632" style={{ textDecoration:'none', color:'white' }}>
                                <Button 
                                sx={{ mt:1, mr:2 }} variant="contained" 
                                startIcon={<CallIcon />}
                                style={{backgroundColor: '#f2f2f2', color: '#2b6777'}}>
                                    +8801773241632
                                </Button>
                            </a>
                            <ShareThisLinkLight  buttonName="Share" />
                        </Grid>
                        <Grid item style={{ textAlign: 'left'}} xs={4} md={5}>
                            <Typography sx={{ my:1, fontSize: 18, fontWeight: 600 }} style={{color: '#c8d8e4'}} variant="h6" component="div">
                                Office Address
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 400 }} style={{color: '#f2f2f2', fontWeight: 500, fontSize: 16 }} variant="" component="div">
                                    Bengal House <br />
                                    75 Gulshan Avenue <br />
                                    Road 30, Gulshan-1 <br />
                                    Dhaka-1212, Bangladesh 
                                    
                            </Typography>
                        </Grid>
                        {/* <Grid item style={{ textAlign: 'left'}} xs={6} md={3}>
                            <Typography sx={{ my:1, fontSize: 18, fontWeight: 600 }} style={{color: '#c8d8e4'}} variant="h6" component="div">
                                Residence
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 400 }} style={{color: '#f2f2f2', fontWeight: 500, fontSize: 16 }} variant="" component="div">
                                    1430-Narayanganj <br />
                                    Dhaka, Bangladesh
                            </Typography>
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
            <Typography sx={{ p:1, fontSize: 13, fontWeight: 400, color: '#c8d8e4' }} variant="" component="div">
                Copyright &copy; 2022 - All Rights Reserved | Developed by: <a href="https://www.linkedin.com/in/sharif00112/" target="blank" style={{ textDecoration:'none', color:'white' }}>Md Sharif Hossain</a> 
            </Typography>
        </div>
    );
};

export default Footer;