import React from 'react'

const GMAT = () => {
    return (
        <div>
            GMAT
        </div>
    )
}

export default GMAT