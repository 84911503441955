import { Box, Button, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTitle } from '../../../hooks/useTitle';
import ieltsImg from '../../../images/articles/IELTS_overview_resources_sharif-hossain.png';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ShareThisLinkDark from '../../Shared/ShareThisLink/ShareThisLinkDark';
import { Helmet } from 'react-helmet';

const IELTS = () => {
    useTitle('IELTS Overview | Sharif Hossain');
    return (
        <div>
            {/* <Typography>IELTS</Typography> */}
            <Helmet>
                <title>IELTS Overview | Sharif Hossain</title>
                <meta name="description" content="IELTS overview and free resources" />
                <meta name="keywords" content="sharif, hossain, nsu, north south university, ielts, ielts overview, ielts guide, self ielts, ielts resources, free ielts resources" />
            </Helmet> 

            <Typography sx={{ m: 3, mb:5, color: '#2b6777', fontWeight: 600 }} variant="h4" component="div">
                International English Language Testing System (IELTS) Overview
            </Typography>
            <Typography sx={{ mx: 4, my:3, color: '', fontWeight: 500, textAlign: 'right' }} variant="body1" component="div">
                <span><b>Author:</b></span> Md Sharif Hossain 
                <br/>
                <span><b>Published at:</b></span> December 2023
                <br/>
                <span><b>Last modified:</b></span> May 2024
            </Typography> 
            <img style={{ width: '30%', borderRadius: '0%' }} src={ieltsImg} alt="ielts overview and free resources" />
            <br/> <br/>
            <Typography sx={{ mx: 4, mb:3, color: '', fontWeight: 600 }} variant="h6" component="div">
                 <i>"If you know the enemy and know yourself, you need not fear the result of a hundred battles. If you know yourself but not the enemy, for every victory gained, you will also suffer a defeat. If you know neither the enemy nor yourself, you will succumb in every battle." 
                 <br/>—Sun Tzu (The Art of War)</i>
            </Typography>

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
                A brief on IELTS:  
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                You can prepare for the IELTS test from home. First, you need to know about the test. Considering the IELTS test as your enemy, you need to be clear about the question types, the requirements, the question complexity level, and finally, your own command over the designated fields. To check your level, simply register on the British Council website and try a free mock test. You can follow their website (IELTS section) in-depth to gain a better understanding of the test. 
            </Typography>
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                IELTS stands for the International English Language Testing System – an English Language proficiency test. Globally, there are more than 4 million test takers annually, making IELTS the world’s most popular English language proficiency test for higher education and global migration. IELTS is developed and run by the British Council in partnership with IDP Education and Cambridge Assessment English. 
                <br/><br/>
                A complete IELTS test consists of four different modules: <b> Listening, Reading, Writing, and Speaking. </b> 
                <br/> <br/>
                Let's go through some common information- 
            </Typography> 

            <Box sx={{ mx: 5 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30%' }}><b>Official site</b></TableCell>
                                <TableCell style={{ width: '70%' }}><a href='https://takeielts.britishcouncil.org' target='blank'>takeielts.britishcouncil.org </a></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell><b>Test Taker</b></TableCell>
                                <TableCell>
                                    <li>British Council (recommended) </li>
                                    <li>IDP</li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test types</b></TableCell>
                                <TableCell>
                                    <li>IELTS Academic </li>
                                    <li>IELTS General Training </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Score range</b></TableCell>
                                <TableCell>
                                    Band 1 to 9, in half-point increments.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Testing modules</b></TableCell>
                                <TableCell>
                                    <li>Listening</li>
                                    <li>Reading </li>
                                    <li>Writing</li>
                                    <li>Speaking</li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test fee</b></TableCell>
                                <TableCell>
                                    BDT 21,000 ~ 23,000
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test system</b></TableCell>
                                <TableCell>
                                    <li>IELTS at the test center </li>
                                    <li>IELTS at home  </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test media</b></TableCell>
                                <TableCell>
                                    <li>Computer-based</li>
                                    <li>Paper-based </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Test time</b></TableCell>
                                <TableCell>
                                    <li>Q2 hours and 45 minutes long. </li>
                                    <li>The Listening, Reading, and Writing sections take place on the same day with no breaks in between. </li>
                                    <li>The Speaking section may be scheduled on the same day or up to 7 days before or after the other sections.</li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Identification method</b></TableCell>
                                <TableCell>Passport or any National Photo ID  </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Result Pending Time </b></TableCell>
                                <TableCell>
                                    <li>Paper-based: approximately 13–15 days after the test date</li>
                                    <li>Computer-based: approximately 3–5 days after the test date. </li>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><b>Result validation </b></TableCell>
                                <TableCell>2 years following the individual's test date. </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <br/><br/>
            
            {/* title-text pair */}
            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Which IELTS is for you? 
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                IELTS is aimed at people who want to study in an English-speaking environment, work in or emigrate to an English-speaking country, or get a job in your own country where English proficiency is required. For this reason, there are two IELTS tests to choose from: 
            </Typography> 
            {/* add the table */}

            <Box sx={{ mx: 5 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '50%', textAlign: 'center' }}><b>IELTS Academic (AC)</b></TableCell>
                                <TableCell style={{ width: '50%', textAlign: 'center'  }}><b>IELTS General Training (GT)</b> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>This test can help you secure university acceptance, student visas and prove your English ability to professional organizations. </TableCell>
                                <TableCell>This test measures your English proficiency in the workplace and is often useful to prove your level when applying for a new position in an English-speaking company or organization. </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <br/><br/>

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                IELTS Test Structure:
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                IELTS assesses the four key skills outlined below-
            </Typography> 

            <Box sx={{ mx: 5 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '35%', fontWeight: 500 }}><b>Listening </b> <br/>40 Questions <br/> 40-50 minutes </TableCell>
                                <TableCell style={{ width: '65%', fontWeight: 500 }}>This section assesses how well you understand ideas, recognize opinions, and follow the development of an argument. </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ width: '35%', fontWeight: 500 }}><b>Reading </b> <br/>40 Questions <br/> 60 minutes </TableCell>
                                <TableCell style={{ width: '65%', fontWeight: 500 }}>This section assesses how well you read for general sense, main ideas, and details, and whether you understand the author's inferences and opinions. </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ width: '35%', fontWeight: 500 }}><b>Writing </b> <br/>2 tasks <br/> 60 minutes </TableCell>
                                <TableCell style={{ width: '65%', fontWeight: 500 }}>This section evaluates how well and how accurately you organize your ideas and write a response, along with your ability to use wide-ranging vocabulary and grammar. </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ width: '35%', fontWeight: 500 }}><b>Speaking </b> <br/>3 sections <br/> 10-15 minutes </TableCell>
                                <TableCell style={{ width: '65%', fontWeight: 500 }}>This section assesses how well you can communicate opinions and information on everyday topics and common experiences, as well as how you express and justify your opinions. </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <br/>

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
                Self Preparation: 
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
            You can prepare for the IELTS test from home. First, you need to know about the test. Considering the IELTS test as your enemy, you need to be clear about the question types, the requirements, the question complexity level, and finally, your own command over the designated fields. To check your level, simply register on the British Council website and try a free mock test. You can follow their website (IELTS section) in-depth to gain a better understanding of the test. 
            </Typography> 

            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
                Important resources:  
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                I do not recommend following a lot of resources, but rather, I recommend following a limited number of good resources with greater depth. I found the following resources very helpful in preparing for the IELTS test.  
                <ol>
                    <li>
                        For any test, official test guides should be the first priority to be introduced to the test. Sign up for the <span><b>British Council IELTS website</b></span> and go through their general information and life stories about IELTS. 
                    </li>
                    <li>
                        Secondly, if you are not struggling with basic English grammar, you can start practicing with <span><b>Official Cambridge IELTS textbooks (9-18).</b></span> 
                    </li>
                    <li>
                        Consider collecting some additional resources which are really helpful. For instance, <span><b>Rachel Mitchell</b></span> IELTS books (especially the writing part), <span><b>Makkar Speaking</b></span> Guide, etc. 
                    </li>
                    <li>
                        STRATEGY is the most crucial key to getting a higher band in IELTS. For this, consider a good tutor or YouTube videos. Some helpful YouTube channels for IELTS are the following: 
 
                        <ul>
                            <li><b>IELTS-up Online lessons (writing) - <a href='https://www.youtube.com/@ielts-uponlinelessons7695' target='blank'>www.youtube.com/@ielts-uponlinelessons7695</a></b></li>
                            <li><b>Asad Yaqub IELTS (strategies) - <a href='https://www.youtube.com/@AsadYaqubOfficial' target='blank'>www.youtube.com/@AsadYaqubOfficial</a></b></li>
                            <li><b>IELTS Liz (strategies) - <a href='https://www.youtube.com/@ieltsliz' target='blank'>www.youtube.com/@ieltsliz</a></b></li>
                            <li><b>Fastrack IELTS (strategies) - <a href='https://www.youtube.com/@FasTrackIELTS' target='blank'>www.youtube.com/@FasTrackIELTS</a></b></li>
                            <li><b>IELTS Advantage (strategies) - <a href=' https://www.youtube.com/@talhaomer167' target='blank'>www.youtube.com/@Ieltsadvantage</a></b></li>
                        </ul>
                    </li>
                    <li>
                        Finally, consider attempting <span><b>Mock Tests</b></span> at the test centers to gain real exam experience before the test day.  
                    </li>

                </ol>
            </Typography> 
            
            <Typography sx={{ mx: 4, mb:1, color: '', fontWeight: 700, textAlign: 'justify' }} variant="h6" component="div">
            Source of the necessary materials: 
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 500, textAlign: 'justify' }} variant="body1" component="div">
                All these resources are available in bookshops and online. Some of them might not be free. However, here I am sharing some online and free versions of essential resources. I DO NOT recommend using pirated resources, but as a student of a poor economic country, we might reconsider it. Please read the Disclaimer first (uploaded in the home directory of the shared folder).
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Google Drive Link- <a style={{ textDecoration: '', color: 'blue'}} href='https://drive.google.com/drive/folders/1UZdUB_IFU9bHuE4UTsfwqh4e0-pWTN4J?usp=sharing' target='blank'>Click Here</a>
            </Typography> 
            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 700, textAlign: 'justify' }} variant="body1" component="div">
                Author's Email- <a style={{ textDecoration: '', color: '#253746'}} href='mailto:sh.hossain.24@gmail.com' target='blank'>sh.hossain.24@gmail.com</a> | <a style={{ textDecoration: '', color: '#253746'}} href='https://www.facebook.com/sharif.00112' target='blank'>Facebook</a>
            </Typography> 
            

            <Tooltip title="Click for the full version">
                <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ m:2, textDecoration: 'none' }}>
                    {/* <Link to={'/about'} style={{ textDecoration: 'none', color: 'white' }}>More About Me</Link> */}
                    <a href='https://docs.google.com/document/d/1qJXUlXDbV3ArHV46X7rWP4rFe4e79OREVByRbvfe_HE/edit?usp=sharing' target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Read in Google Doc</a>
                </Button>
            </Tooltip>
            <Tooltip title="Click to view resources">
                <Button variant="contained" style={{ backgroundColor: '#253746' }} sx={{ m: 2, textDecoration: 'none' }}>
                    {/* <Link to={'/about'} style={{ textDecoration: 'none', color: 'white' }}>More About Me</Link> */}
                    <a href='https://drive.google.com/drive/folders/1UZdUB_IFU9bHuE4UTsfwqh4e0-pWTN4J?usp=sharing' target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Resources</a>
                </Button>
            </Tooltip>
            
            <Typography sx={{ mx: 10, mb:5, mt:3, color: '', fontWeight: 700 }} variant="body1" component="div">
                <i>[Note: Please send an access request if any link is restricted]</i> 
            </Typography>

            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 700, textAlign: 'center' }} variant="body1" component="div">
                Thanks, and the best wishes!
            </Typography> 
            
            {/* share the article button*/}
            <ShareThisLinkDark buttonName="Share This Article" />
            <br/><br/>

            <Typography sx={{ mx: 4, mb:2, color: '', fontWeight: 300, textAlign: 'left' }} variant="body2" component="div">
                Information source: <a style={{ textDecoration: '', color: 'black'}} href='https://takeielts.britishcouncil.org/' target='blank'>IELTS official website </a>
            </Typography> 
        </div>
    )
}

export default IELTS