import { Card, CardContent, CardMedia, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const ProjectDetails = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('id');

    const [project, setProject] = useState({});

    useEffect(() => {
        const fetchProjects = async () => {
          try {
            const response = await fetch('fakeDBProjects.json');
            const data = await response.json();
      
            for (let i = 0; i < data.length; i++) {
              if (data[i].ID === projectId) {
                setProject(data[i]);
                break; // Exit the loop once the project is found
              }
            }
          } catch (error) {
            console.error('Error fetching projects:', error);
          }
        };

        if (projectId) {
            fetchProjects();
        } else {
            // If serviceId is not available, navigate to the appropriate page
            navigate('/#projects');
        }
    }, [projectId, navigate]);

    return (
        <div>
            <Typography sx={{fontWeight: 500, m: '25px auto 15px auto', color: '#253746'}} gutterBottom variant="h4" component="div">
                Project: {project.title}
            </Typography>
            <Typography sx={{m:1}} variant="body1" color="black">
                {project.shortDescription}
            </Typography>

            <Container>
                <Card sx={{ maxWidth: 'auto' }}>
                    <CardMedia
                        component="img"
                        src={project.imgURL}
                        alt={project.title}
                        style={{ width: '100%', margin: '0 3px', marginTop: '10px' }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            Live Preview: <a href={project.liveProjectLink} target="_blank" rel="noopener noreferrer">{project.liveProjectLink}</a>
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div">
                            Github FrontEnd: <a href={project.frontendGitLink} target="_blank" rel="noopener noreferrer">{project.frontendGitLink}</a>
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div">
                            Github BackEnd: <a href={project.backendGitLink} target="_blank" rel="noopener noreferrer">{project.backendGitLink}</a>
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div">
                            First published: {project.firstVersionLaunchedAt}
                        </Typography>
                        <Typography sx={{m:1}} variant="body1" color="black">
                            Technologies: {project.technologiesUsed}
                        </Typography>
                        <Typography sx={{m:1}} variant="h6" color="black">
                            Overview:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {project.detailDescription}
                        </Typography>
                        <Typography sx={{mt:3}} variant="h6" color="black">
                            More Images:
                        </Typography>
                    </CardContent>
                    {/* <hr sx={{width: '50%', py:3 }}/>
                    <hr sx={{width: '50%', py:3 }}/> */}
                    <CardMedia
                        component="img"
                        src={project.imgURL}
                        alt={project.title}
                        style={{ width: '100%', margin: '0 auto', marginTop: '10px' }}
                    />
                    <hr sx={{width: '50%', py:3 }}/>
                    <hr sx={{width: '50%', py:3 }}/>
                    <CardMedia
                        component="img"
                        src={project.imgURL2}
                        alt={project.title}
                        style={{ width: '100%', margin: '0 auto', marginTop: '10px' }}
                    />
                    <hr sx={{width: '50%', py:3 }}/>
                    <hr sx={{width: '50%', py:3 }}/>
                    <CardMedia
                        component="img"
                        src={project.imgURL3}
                        alt={project.title}
                        style={{ width: '100%', margin: '0 auto', marginTop: '10px' }}
                    />
                    <hr sx={{width: '50%', py:3 }}/>
                    <hr sx={{width: '50%', py:3 }}/>
                    <CardMedia
                        component="img"
                        src={project.imgURL4}
                        alt={project.title}
                        style={{ width: '100%', margin: '0 auto', marginTop: '10px' }}
                    />
                    <hr sx={{width: '50%', py:3 }}/>
                    <hr sx={{width: '50%', py:3 }}/>
                    <CardMedia
                        component="img"
                        src={project.imgURL5}
                        alt={project.title}
                        style={{ width: '100%', margin: '0 auto', marginTop: '10px' }}
                    />
                    <hr sx={{width: '50%', py:3 }}/>
                    <hr sx={{width: '50%', py:3 }}/>
                     {/* <br /><br /> */}
                    {/* <CardActions  style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button size="small">Share</Button>
                        <Button size="small" variant='outlined' onClick={handleBookService}>
                            Book Service
                        </Button>
                    </CardActions> */}
                </Card>
            </Container>

        </div>
    );
};

export default ProjectDetails;