import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Technology from '../Technology/Technology';


// https://i.ibb.co/KNBcBSt/expressjs.png
// https://i.ibb.co/yVX9f7v/nosql.png
// https://i.ibb.co/FKfLG1H/json.png
// https://i.ibb.co/W5LR7jJ/github.png
// https://i.ibb.co/HPRQ1Yp/angular.png
// https://i.ibb.co/0K3Yjkk/mui.png
// https://i.ibb.co/HXgz9C7/bootstrap.png
// https://i.ibb.co/q05NP0d/tailwind.png
// https://i.ibb.co/g4rh6VV/api.png
// https://i.ibb.co/FKNvdXb/jwt.png
// https://i.ibb.co/mX47613/sql.png
// https://i.ibb.co/mNKY0YG/cmd.png
// https://i.ibb.co/26dBK31/git.png
// https://i.ibb.co/cXGSYPk/html.png
// https://i.ibb.co/kBT045r/css.png
// https://i.ibb.co/gtBrWmF/javascript.webp
// https://i.ibb.co/3d5RG9V/java.png
// https://i.ibb.co/RBz0yF1/python.png
// https://i.ibb.co/Y2kbK64/mongoDB.png
// https://i.ibb.co/C58RMcQ/node.png
// https://i.ibb.co/1qhddg7/react.png
// https://i.ibb.co/C8NTKyQ/spring.png
// https://i.ibb.co/KGBs6KG/typescript.png
// https://i.ibb.co/8mCGx4W/ChatGPT.png
// https://i.ibb.co/DbMq0W6/docker.png
// https://i.ibb.co/BgLkDcW/jp-notebook.png
// https://i.ibb.co/sm5M6r1/leetcode.png

const myTechnologies = [
    {
        platform: 'React',
        link: 'https://react.dev/',
        logo: 'https://i.ibb.co/1qhddg7/react.png'
    },
    {
        platform: 'Node',
        link: 'https://nodejs.org/en',
        logo: 'https://i.ibb.co/C58RMcQ/node.png'
    },
    {
        platform: 'Express',
        link: 'https://expressjs.com/',
        logo: 'https://i.ibb.co/KNBcBSt/expressjs.png'
    },
    {
        platform: 'MongoDB',
        link: 'https://www.mongodb.com/',
        logo: 'https://i.ibb.co/Y2kbK64/mongoDB.png'
    },
    {
        platform: 'Java',
        link: 'https://www.java.com/en/',
        logo: 'https://i.ibb.co/3d5RG9V/java.png'
    },
    {
        platform: 'Python',
        link: 'https://www.python.org/',
        logo: 'https://i.ibb.co/RBz0yF1/python.png'
    },
    {
        platform: 'Jupyter Notebook',
        link: 'https://jupyter.org/',
        logo: 'https://i.ibb.co/rmzBnrw/jp-notebook.jpg'
    },
    {
        platform: 'LeetCode',
        link: 'https://leetcode.com/',
        logo: 'https://i.ibb.co/sm5M6r1/leetcode.png'
    },
    {
        platform: 'JavaScript',
        link: 'https://www.javascript.com/',
        logo: 'https://i.ibb.co/gtBrWmF/javascript.webp'
    },
    {
        platform: 'HTML',
        link: 'https://html.com/',
        logo: 'https://i.ibb.co/cXGSYPk/html.png'
    },
    {
        platform: 'CSS',
        link: 'https://en.wikipedia.org/wiki/CSS',
        logo: 'https://i.ibb.co/kBT045r/css.png'
    },
    {
        platform: 'Bootstrap',
        link: 'https://getbootstrap.com/',
        logo: 'https://i.ibb.co/HXgz9C7/bootstrap.png'
    },
    {
        platform: 'Tailwind CSS',
        link: 'https://tailwindcss.com/',
        logo: 'https://i.ibb.co/q05NP0d/tailwind.png'
    },
    {
        platform: 'MUI',
        link: 'https://mui.com/',
        logo: 'https://i.ibb.co/0K3Yjkk/mui.png'
    },
    {
        platform: 'Angular',
        link: 'https://angular.io/',
        logo: 'https://i.ibb.co/HPRQ1Yp/angular.png'
    },
    {
        platform: 'Spring',
        link: 'https://spring.io/',
        logo: 'https://i.ibb.co/C8NTKyQ/spring.png'
    },
    {
        platform: 'TypeScript',
        link: 'https://www.typescriptlang.org/',
        logo: 'https://i.ibb.co/KGBs6KG/typescript.png'
    },
        {
        platform: 'SQL',
        link: 'https://en.wikipedia.org/wiki/SQL',
        logo: 'https://i.ibb.co/mX47613/sql.png'
    },
    // {
    //     platform: 'NoSQL',
    //     link: 'https://en.wikipedia.org/wiki/NoSQL',
    //     logo: 'https://i.ibb.co/yVX9f7v/nosql.png'
    // },
    // {
    //     platform: 'JSON',
    //     link: 'https://www.json.org/json-en.html',
    //     logo: 'https://i.ibb.co/FKfLG1H/json.png'
    // },
    // {
    //     platform: 'Git',
    //     link: 'https://git-scm.com/',
    //     logo: 'https://i.ibb.co/26dBK31/git.png'
    // },
    {
        platform: 'GitHub',
        link: 'https://github.com/Sharif-00112',
        logo: 'https://i.ibb.co/W5LR7jJ/github.png'
    },
    {
        platform: 'Docker',
        link: 'https://www.docker.com/',
        logo: 'https://i.ibb.co/DbMq0W6/docker.png'
    },
    {
        platform: 'JWT',
        link: 'https://jwt.io/',
        logo: 'https://i.ibb.co/FKNvdXb/jwt.png'
    },
    {
        platform: 'API',
        link: 'https://en.wikipedia.org/wiki/API',
        logo: 'https://i.ibb.co/g4rh6VV/api.png'
    },
    {
        platform: 'CMD',
        link: 'https://en.wikipedia.org/wiki/Cmd.exe',
        logo: 'https://i.ibb.co/mNKY0YG/cmd.png'
    },
    {
        platform: 'ChatGPT',
        link: 'https://chat.openai.com/',
        logo: 'https://i.ibb.co/8mCGx4W/ChatGPT.png'
    },
];

const Technologies = () => {
    return (
        <div style={{backgroundColor: 'lightgray'}}>
            <Container>
                <Box sx={{ flexGrow: 1, py:2 }}>
                    <Typography sx={{ p: 2, color: '#2b6777', fontWeight: 700 }} variant="h4" component="div">
                        Technologies
                    </Typography>
                    
                    <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        {
                            myTechnologies.map(myTechnology => (
                                <Grid xs={4} sm={3} md={2} key={myTechnology.link}>
                                    <Technology
                                        key = {myTechnology.link}
                                        myTechnology = {myTechnology}
                                    ></Technology>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
        </div>
    );
};

export default Technologies;