import React from 'react';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import errorIMG from '../../images/dribbble-404-error.gif'
import { useTitle } from '../../hooks/useTitle';


const NotFound = () => {
    useTitle('Sharif Hossain|404-ERROR');

    return (
        <div style={{backgroundColor: '#ffffff'}}>
            <Typography sx={{ pt:3, fontSize: 25, fontWeight: 600 }} style={{color: '#2b6777 '}} variant="h4" component="div">
                Error 404!
                <br />
                Perhaps I've mistakenly eaten the cables!
            </Typography>
            <img 
                style={{width: '50%' }} 
                src={errorIMG} 
                alt="404 Page Not Found!" 
            />
            <br />
            <NavLink 
                style={{ textDecoration:'none' }}
                to='/'>
                <Button variant="contained" sx={{ mb:3 }} style={{backgroundColor: '#2b6777 ', color: '#f2f2f2'}}>Go to Home</Button>
            </NavLink>
        </div>
    );
};

export default NotFound;