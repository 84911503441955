import React from 'react';
import { useTitle } from '../../../hooks/useTitle';
import AboutSummary from '../AboutSummary/AboutSummary';
import Banner from '../Banner/Banner';
import Contact from '../Contact/Contact';
import OnlineEngagements from '../OnlineEngagements/OnlineEngagements';
// import Projects from '../Projects/Projects';
import ProjectsSummary from '../ProjectsSummary/ProjectsSummary';
import Technologies from '../Technologies/Technologies';
import { Helmet } from 'react-helmet';


const Home = () => {
    useTitle('Md Sharif Hossain');

    return (
        <div>
            <Helmet>
                <title>Sharif Hossain</title>
                <meta name="description" content="Portfolio website of Md Sharif Hossain" />
                <meta name="keywords" content="sharif, hossain, sharif00112, sharif hossain facebook, sharif bengal, sharif bgi, sh.hossain.24@gmail.com, 01773241632, it solutions, it solution specialist, web developer, nsu, north south university" />
            </Helmet> 

            <Banner></Banner>
            <Technologies></Technologies>
            <OnlineEngagements></OnlineEngagements>
            <ProjectsSummary></ProjectsSummary>
            <AboutSummary></AboutSummary>
            <Contact></Contact>
        </div>
    );
};

export default Home;