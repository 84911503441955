// import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { useTitle } from '../../../hooks/useTitle';
// import { Link } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Article from '../Article/Article';

// https://i.ibb.co/h16ZpKk/GMAT-overview-resources-sharif-hossain.png
// https://i.ibb.co/gJntLdc/GRE-overview-resources-sharif-hossain.png
// https://i.ibb.co/gz0KsNY/IELTS-overview-resources-sharif-hossain.png
// https://i.ibb.co/5RQT5Sp/TOEFL-overview-resources-sharif-hossain.png

const myArticles = [
    {
        articleId: '001',
        articleLink: '/articles/ielts',
        // articleLink: 'https://docs.google.com/document/d/1qJXUlXDbV3ArHV46X7rWP4rFe4e79OREVByRbvfe_HE/edit?usp=sharing',
        title: 'Beat IELTS all by yourself',
        miniDetail: 'International English Language Testing System (IELTS) Overview',
        largeDetail: 'lorem30 lorem30 lorem30 lorem30 lorem30 ',
        thumbnail: 'https://i.ibb.co/gz0KsNY/IELTS-overview-resources-sharif-hossain.png',
        image1: '',
        image2: '',
        image3: '',
        author: '',
        dateCreated: '',
        lastModified: '',
    },
    {
        articleId: '002',
        // articleLink: 'https://docs.google.com/document/d/1GZvpZ4pllChHf_C2JtkRGpgmZ2F8MzTfXjEs6sd6TD0/edit?usp=sharing',
        articleLink: '/articles/gre',
        title: 'GRE is no longer a nightmare!',
        miniDetail: 'Graduate Record Examinations (GRE) Overview',
        largeDetail: 'lorem30 lorem30 lorem30 lorem30 lorem30 ',
        thumbnail: 'https://i.ibb.co/gJntLdc/GRE-overview-resources-sharif-hossain.png',
        image1: '',
        image2: '',
        image3: '',
        author: '',
        dateCreated: '',
        lastModified: '',
    },
    {
        articleId: '003',
        articleLink: 'https://drive.google.com/drive/folders/1vDi4L-PcXFw6Xtkt44CtNFICaOU_mn1k?usp=sharing',
        title: 'Get introduced to TOEFL',
        miniDetail: 'Test of English as a Foreign Language (TOEFL) Resources',
        largeDetail: 'lorem30 lorem30 lorem30 lorem30 lorem30 ',
        thumbnail: 'https://i.ibb.co/5RQT5Sp/TOEFL-overview-resources-sharif-hossain.png',
        image1: '',
        image2: '',
        image3: '',
        author: '',
        dateCreated: '',
        lastModified: '',
    },
    {
        articleId: '004',
        articleLink: 'https://drive.google.com/drive/folders/1vo8q1j65leZcvE4GXWYmfXFY9FMJ9VZV?usp=sharing',
        title: 'A B C D of GMAT',
        miniDetail: 'Graduate Management Admission Test (GMAT) Resources',
        largeDetail: 'lorem30 lorem30 lorem30 lorem30 lorem30 ',
        thumbnail: 'https://i.ibb.co/h16ZpKk/GMAT-overview-resources-sharif-hossain.png',
        image1: '',
        image2: '',
        image3: '',
        author: '',
        dateCreated: '',
        lastModified: '',
    },
]

const Articles = () => {
    useTitle('Articles | Sharif Hossain');

    return (
        <div style={{backgroundColor: '#2b6777'}}>
            <Container>
                <Box sx={{ flexGrow: 1, py:2 }}>
                    <Typography sx={{ p: 2, color: '#f2f2f2', fontWeight: 700 }} variant="h5" component="div">
                        Articles and resources you will love to explore
                    </Typography>

                    <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                        {
                            myArticles.map(myArticle => (
                                <Grid xs={12} sm={12} md={12} key={myArticle.articleId}>
                                    <Article
                                        key = {myArticle.articleId}
                                        myArticle = {myArticle}
                                    ></Article>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
            <br/><br/><br/><br/>
        </div>
    );
};

export default Articles;