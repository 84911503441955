import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './Pages/Shared/Navbar/Navbar';
import Home from './Pages/Home/Home/Home';
import Contact from './Pages/Home/Contact/Contact';
import Articles from './Pages/Home/Articles/Articles';
import Footer from './Pages/Shared/Footer/Footer';
import NotFound from './Pages/NotFound/NotFound';
// import AboutSummary from './Pages/Home/AboutSummary/AboutSummary';
import AboutMain from './Pages/Home/AboutMain/AboutMain';
import Researches from './Pages/Home/Researches/Researches';
import ProjectsMain from './Pages/Home/ProjectMain/ProjectMain';
import ProjectDetails from './Pages/Home/ProjectDetails/ProjectDetails';
import NotAvailable from './Pages/NotAvailable/NotAvailable';
import BackToTopButton from './Pages/BackToTopButton/BackToTopButton';
import GRE from './Pages/Home/Articles/GRE';
import IELTS from './Pages/Home/Articles/IELTS';
import GMAT from './Pages/Home/Articles/GMAT';
import TOEFL from './Pages/Home/Articles/TOEFL';

// Color tones: #2b6777 #c8d8e4 #ffffff #f2f2f2 #52ab98

function App() {
  return (
    <div className="App">
      <Router>
          <Navbar></Navbar>
          <BackToTopButton></BackToTopButton>
          <Routes>
            <Route exact path = '/' element={<Home/>}/>
            {/* <Route exact path = '/home' element={<Home/>}/> */}
            <Route exact path = '/about' element={<AboutMain/>}/>
            <Route exact path = '/projects' element={<ProjectsMain/>}/>
            <Route exact path = '/research' element={<Researches/>}/>
            <Route exact path = '/contact' element={<Contact/>}/>
            <Route exact path = '/articles' element={<Articles/>}/>
            <Route exact path = '/blogs' element={<Articles/>}/>
            <Route exact path = '/articles/gre' element={<GRE/>}/>
            <Route exact path = '/blogs/gre' element={<GRE/>}/>
            <Route exact path = '/articles/ielts' element={<IELTS/>}/>
            <Route exact path = '/blogs/ielts' element={<IELTS/>}/>
            <Route exact path = '/articles/gmat' element={<GMAT/>}/>
            <Route exact path = '/articles/toefl' element={<TOEFL/>}/>
            <Route exact path = '/temporarilyNotAvailable' element={<NotAvailable/>}/>
            
            {/* <Route path = '/home/project' element={<ProjectDetails/>}/> */}
            <Route path = '/projects/project' element={<ProjectDetails/>}/>
            <Route path = '/project' element={<ProjectDetails/>}/>
            
            <Route path = '*' element={<NotFound/>}/>
          </Routes>
          <Footer></Footer>
        </Router>
    </div>
  );
}

export default App;
