import { Typography } from '@mui/material';
import React from 'react';
import { useTitle } from '../../../hooks/useTitle';

const Researches = () => {
    useTitle('Sharif Hossain | Research')
    return (
        <div>
            <hr />
            <Typography sx={{ m: 3, color: '#2b6777', fontWeight: 600 }} variant="h4" component="div">
                Research History
            </Typography>
            <Typography sx={{ m: 3, color: '#2b6777', fontWeight: 400 }} variant="h6" component="div">
                Coming soon...
            </Typography>
            <hr />
        </div>
    );
};

export default Researches;